@import "general/colors";
@import "general/buttons";
@import "general/typography";
//* =================== button floating ====================
.button__floating--whatsapp {
    z-index: 1000;
    color: #fff;
    font-weight: bold;
    background: #22c55e;
    border-radius: 999px;
    justify-content: center;
    align-items: center;
    padding: 24px;
    padding-left: 4rem;
    display: flex;
    position: fixed;
    bottom: 2rem;
    left: -48px;
    overflow: hidden;
    gap: 8px;
    box-shadow: 0 4px 10px #00000040;
    &>img {
        width: 24px;
        height: 24px;
    }
    text-decoration: none;
    &:hover{
        color: #fff;
        background: #16a34a;
    }
}

.button__floating--callme {
    background: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    position: fixed;
    border-radius: 50%;
    padding: 20px;
    bottom: 40px;
    right: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    &>img {
        width: 40px;
    }
    z-index: 1000;
    display: none;
}

.button__floating--back-start {
    background: $primary-two;
    position: fixed;
    border-radius: 50%;
    bottom: 150px;
    right: 28px;
    padding: 12px;
    &>.bx {
        color: $white;
        padding: 0;
        margin: 0;
        font-size: 80px;
    }
    width: 50px;
    height: 50px;
    &>.bx {
        font-size: 25px;
    }
    z-index: 1000;
    display: none;
}

@media screen and (max-width: 992px) {
    // .button__floating--whatsapp {
    //     padding: 10px;
    //     width: 64px;
    //     height: 64px;
    //     &>img {
    //         width: 40px;
    //         height: 40px;
    //     }
    // }
    .button__floating--callme {
        padding: 10px;
        bottom: 30px;
        right: 20px;
        &>img {
            width: 40px;
        }
    }
    .button__floating--back-start {
        &>.bx {
            font-size: 25px;
        }
        right: 28px;
        bottom: 150px;
    }
}

@media screen and (max-width: 573px) {
    .button__floating--back-start {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 13px;
        width: 13px;
        right: 40px;
        display: none;
    }
}
